import React from "react";
import { IconContext } from "react-icons";
import data from '../data/contact';

function Footer() {
  return (
    <div>
      <footer className="footer fixed-bottom">
        <div className="container">
        <div className="row align-items-center my-2"> 
        {data.map((s, index) => (
            <div key={index} className="col-md-6 align-items-center text-center">
              <a href={s.link}>
              <IconContext.Provider value={{ color: s.color, size: s.size}}>
                {s.icon}
                </IconContext.Provider>
              </a>
            </div>
          ))}
          </div>
          <div className="row align-items-center my-2">
            <div className="col-lg-12 align-items-center">
              <p className="m-0 text-center text-white">
                Copyright &copy; Jerky676 2023
              </p>
          </div>
          </div>
        </div>      
      </footer>
    </div>
  );
}

export default Footer;